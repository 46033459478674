/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
/*new*/

/* ----------------------------------------------------------------
    Start Basics
-----------------------------------------------------------------*/
:root {
  --primary-color: #74d6a9 !important;
  --primary-color-dark: #198a55 !important;
  --secondary-color: #ffffff !important;
  --primary-color-light: #eefff7 !important;
  --h6-text-color: #7f7f7f !important;
}

@font-face {
  font-family: "RawengulkSans";
  src: url("fonts/AdventPro-Light.ttf");
}

@font-face {
  font-family: "AdventProLight";
  src: url("fonts/AdventPro-Light.ttf");
}

@font-face {
  font-family: "BodoniXT";
  src: url("fonts/AdventPro-Light.ttf");
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  list-style: none;
  word-wrap: break-word;
}
html,
body,
#root,
.App {
  height: 100%;
}
body {
  color: #000;
  line-height: 1.3;
  font-weight: 400;
  font-size: 18px;
  font-family: "RawengulkSans", sans-serif !important;
  overflow-x: hidden !important;
}

p {
  font-size: 18px;
  font-weight: 300;
  color: var(--h6-text-color);
  line-height: 1.6;
  margin: 0;
  text-align: justify;
}

h1 {
  font-size: 40px;
  font-family: "BodoniXT", sans-serif;
}
h2 {
  font-size: 35px;
  font-family: "BodoniXT", sans-serif;
}
h3 {
  font-family: "RawengulkSans", sans-serif;
  font-size: 30px;
  margin-bottom: 20px;
}

h4 {
  font-family: "RawengulkSans", sans-serif;
  font-size: 25px;
  margin-bottom: 20px;
}

h5 {
  font-family: "RawengulkSans", sans-serif;
}

h6 {
  font-family: "RawengulkSans", sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: var(--h6-text-color);
  line-height: 1.6;
  margin: 0;
  text-align: justify;
}

/*table*/
.table td {
  width: 50%;
}

.table thead th,
.table td,
.table th {
  vertical-align: middle;
  border-style: none;
  padding-top: 0.2rem;
  padding-right: 0.75rem;
  padding-bottom: 0.2rem;
  padding-left: 0.75rem;
}

.progress-bar {
  background-color: var(--primary-color);
}

/*anchor*/
a.anchor {
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden;
}

.detail {
  padding-left: 12px;
}

/*timeline*/
.vertical-timeline-element-icon {
  background-color: var(--primary-color-dark);
  box-shadow: 0 0 0 4px var(--primary-color), inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05) !important;
  top: 20px !important;
}

.vertical-timeline-element-content {
  background: transparent;
  border-radius: 0px !important;
  box-shadow: 0 0px 0 var(--primary-color) !important;
}
.vertical-timeline::before {
  background: var(--primary-color);
}
.subTimeline .vertical-timeline-element-content:hover {
  background: transparent;
  transform: scale(1.03) translateX(20px);
  border-radius: 0px !important;
  box-shadow: 0 0px 0 var(--primary-color-dark) !important;
}

.vertical-timeline-element-icon:hover {
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.2);
}

.vertical-timeline-element {
  margin: 0em 0;
}

.subTimeline.vertical-timeline-element {
  margin: 2em 0;
}

.subTimeline .vertical-timeline-element-icon {
  width: 20px;
  height: 20px;
  box-shadow: 0 0 0 2px var(--primary-color), inset 0 1px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 2px rgba(0, 0, 0, 0.05) !important;
}
.subTimeline .vertical-timeline::before {
  width: 0px;
}
.subTimeline .vertical-timeline {
  left: -92px;
}
.subTimeline h3 {
  font-size: 20px;
}
.subTimeline h4 {
  font-size: 18px;
}

.rowSection {
  padding-top: 40px;
}

img {
  width: 100%;
  height: auto;
}

span,
a,
a:hover {
  display: inline-block;
  text-decoration: none;
  color: var(--primary-color-dark);
}

ul,
li,
ol {
  margin: 0px;
  padding: 0px;
}

button:focus {
  outline: none !important;
}

body,
html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

.overlay-section {
  /*background-image: url("../images/banner.jpg");*/
  background-color: var(--primary-color);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  position: relative;
  border-bottom-right-radius: 40%;
}

/*.overlay-section::before{
	background: rgba(0,0,0,0.7);
	position: absolute;
	content: "";
	display: block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}*/

.icon {
  max-height: 20%;
  max-width: 20%;
  margin: 20px;
}
.icon:hover {
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.2);
}

.logo {
  max-height: 5%;
  max-width: 5%;
}

.image-cropper {
  border-radius: 50%;
  border-style: solid;
  border-width: 1.8px;
  border-color: var(--primary-color);
}

/*tool tip*/
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: rgb(151, 19, 19);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
/*end tooltip*/

/*grid*/
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
}

.grid-container > div {
  text-align: center;
}

.item1 {
  vertical-align: middle;
}

.header-area {
  height: 100%;
  /*font-family: "AdventProLight", sans-serif !important;*/
}

.headerTitle {
  font-size: 40px;
  color: var(--secondary-color);
  font-family: "BodoniXT", sans-serif !important;
}

.overlay-section {
  height: 100vh;
}

.top-bar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  background-color: var(--primary-color);
  padding: 20px 0px;
}

.social-media-right {
  float: right;
}
.social-media-right li {
  display: inline-block;
  padding-left: 10px;
}
.social-media-right li a {
  color: #191f1d;
  display: block;
  font-size: 42px;
}
.social-media-right li a:hover {
  color: var(--primary-color);
}

.banner-content {
  position: relative;
  z-index: 2;
  display: table;
  min-height: 100%;
  height: 100%;
}

.modal.modal-scale.fade.show .modal-dialog {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.modal.modal-scale.fade .modal-dialog {
  height: 100%;
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
  transform-origin: center;
  margin: 0 auto;
  opacity: 0.1;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  transition: all 1s;
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal-content .modal-header {
  display: block !important;
}
.modal-header .close {
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 0;
}
.modal-header h5 {
  display: block;
}

.form-control {
  width: 100%;
  margin-bottom: 10px;
  padding: 14px;
  border-color: var(--primary-color);
  border-radius: 0px;
  color: var(--primary-color);
  font-family: "Rubik", sans-serif;
  background: transparent;
  font-size: 14px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.form-control:focus {
  box-shadow: none;
  color: #121212;
  border-color: #121212;
  background: transparent;
  outline: none;
}

.submit-button button {
  background: var(--secondary-color);
}

.with-errors ul li {
  color: red;
}

.inner-content {
  margin-top: 0;
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}
.inner-content h1 {
  color: var(--secondary-color);
  margin: 0px;
  font-size: 35px;
  padding-bottom: 20px;
  font-family: "RawengulkSans", sans-serif;
  font-weight: 400;
}
.inner-content h1 span {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 58px;
}
.inner-content p {
  color: var(--secondary-color);
  font-size: 20px;
  margin-bottom: 30px;
}

.btn-style-one {
  border: 2px solid var(--primary-color);
  text-transform: uppercase;
  color: var(--primary-color);
  padding: 11px 40px;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 16px;
}
.btn-style-one:hover {
  background: var(--primary-color);
  color: var(--secondary-color);
}

.btn-style-topbar {
  border-width: 0px;
  color: var(--secondary-color);
}

.btn-style-topbar:hover {
  border-width: 0px;
  color: var(--primary-color-dark);
}

.title-bar {
  margin-bottom: 30px;
  color: var(--primary-color);
}

.title-bar h2 {
  font-size: 40px;
  font-family: "RawengulkSans", sans-serif;
  color: var(--primary-color);
}

.title-body {
}
.title-body h2 {
  padding-bottom: 20px;
  font-size: 40px;
  color: var(--primary-color);
  font-family: "BodoniXT", sans-serif;
}

.aboutme-area {
  padding-top: 70px;
  padding-bottom: 70px;
}

.inner-box {
  padding: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
  transition: all 0.3s ease 0s;
}
.inner-box h2 {
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
}
.web-img {
  margin: 20px 0px;
  border: 1px solid #121212;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 0 7px;
  border-radius: 50%;
  background: #121212;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--primary-color);
}

.owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  left: -10px;
  top: 50%;
  border: 2px solid var(--primary-color);
  text-transform: uppercase;
  color: var(--primary-color) 0;
  padding: 11px 5px !important;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 16px;
}

.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  right: -10px;
  top: 50%;
  border: 2px solid var(--primary-color);
  text-transform: uppercase;
  color: var(--primary-color);
  padding: 11px 5px !important;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 16px;
}

.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-next:hover {
  background: var(--primary-color) 0;
  color: #121212;
  border: 2px solid var(--primary-color);
}

.education-work-area {
  padding: 0px 0px;
}

.dom-list {
  /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);*/
  background: var(--secondary-color);
  margin-bottom: 30px;
  padding: 10px;
  /*text-align: center;*/
}
.dom-list h6 {
  padding: 10px 0px;
  margin: 0px;
  font-weight: 400;
}

.domain-sd {
  padding: 70px 0px;
  background: #f2f2f2;
}
.sd-inner-box {
  position: relative;
}
.sd-inner-box p {
  color: var(--primary-color);
  font-size: 28px;
  font-weight: 600;
}
.sd-inner-box h3 {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 22px;
}

footer {
  background: #111111;
  padding: 40px 0px;
}
.footer-content a {
  font-size: 38px;
  font-weight: 600;
  color: var(--primary-color);
  font-family: "BodoniXT", sans-serif;
}
.footer-content a span {
  color: var(--secondary-color);
}
.footer-content li a {
  color: var(--secondary-color);
  font-size: 22px;
  margin: 15px 15px;
}
.footer-content li a:hover {
  color: var(--primary-color);
}

.footer-content p {
  font-size: 14px;
}

p.footer-company-name a {
  color: var(--secondary-color);
  font-size: 14px;
  font-weight: 300;
}
p.footer-company-name a:hover {
  color: var(--primary-color);
}

.vertical-timeline::before {
  background: var(--primary-color) !important;
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px black, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}

/* Responsive Css */

@media (min-width: 768px) and (max-width: 991px) {
  .domain-sd {
    text-align: center;
  }
  .inner-content h1 {
    font-size: 24px;
  }
  .inner-content h1 span {
    font-size: 26px;
  }
  .inner-content p {
    font-size: 13px;
  }
  .btn-style-one {
    font-size: 13px;
    padding: 11px 18px;
  }
  .title-bar h2 {
    font-size: 24px;
    color: var(--primary-color);
  }
  .logo {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .subTimeline .vertical-timeline {
    left: -76px;
  }
  .top-bar {
    position: fixed;
  }
  .domain-sd {
    text-align: center;
  }
  .inner-content h1 {
    font-size: 24px;
  }
  .inner-content h1 span {
    font-size: 26px;
  }
  .inner-content p {
    font-size: 13px;
  }
  .btn-style-one {
    font-size: 13px;
    padding: 11px 18px;
  }
  .title-bar h2 {
    font-size: 24px;
    color: var(--primary-color);
  }
  .social-media-right li a {
    font-size: 22px;
  }
  .social-media-right {
    float: none;
    text-align: center;
  }
  .logo {
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
}
